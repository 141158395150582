import React from "react"
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Cart from "../components/cart"
import { Container, Row } from "react-bootstrap"
import Checkout from "../components/checkout"

const CartScreen = () => {
    return (
        <Layout>
            <Container>
                <Row>
                    <p>Congrats, you paid.</p>
                </Row>
            </Container>
        </Layout>
    )
}
export default CartScreen